export const PANORAMA = {
  options: {
    name: "interior-x-cross-8",
    items: [
      {
        src: require("@/assets/img/cars/x-cross-8/interior/light-q75.webp"),
        srcMobile: require("@/assets/img/cars/x-cross-8/interior/mobile/light-q25.webp"),
        startLookAt: 270,
      },
      {
        src: require("@/assets/img/cars/x-cross-8/interior/dark-q75.webp"),
        srcMobile: require("@/assets/img/cars/x-cross-8/interior/mobile/dark-q25.webp"),
        startLookAt: 270,
      },
    ],
  },
  infospots: [
    {
      position: {
        x: 50,
        y: -2000,
        z: 4400,
      },
      title:
        "Передние кресла с электрорегулировками, памятью положений и вентиляцией сидений",
      desc: "",
      media: { picture: require("@/assets/img/cars/x-cross-8/media/5.webp") },
      gtmReachGoal: "x-cross-8_interior_pin-doors",
    },
    {
      position: {
        x: 4300,
        y: -2100,
        z: 0,
      },
      title: "Атмосферная подсветка салона с большим выбором цветов",
      desc: "Всегда можно подобрать свет под настроение.",
      media: { picture: require("@/assets/img/cars/x-cross-8/media/6.webp") },
      gtmReachGoal: "x-cross-8_interior_pin-doors",
    },
    {
      position: {
        x: 4300,
        y: -2000,
        z: 1100,
      },
      title: "Дистанционный запуск двигателя",
      desc: "XCITE X-Cross 8 встретит вас теплом или прохладой — всё зависит от сезона.",
      media: { picture: require("@/assets/img/cars/x-cross-8/media/7.webp") },
      gtmReachGoal: "x-cross-8_interior_pin-doors",
    },
    {
      position: {
        x: 4300,
        y: 0,
        z: 1800,
      },
      title: "Теплые функции",
      desc: "Ветровое и заднее стекла, боковые зеркала, форсунки омывателя, кресла первого и второго ряда, а также рулевое колесо снабжены функцией подогрева. XCITE X-Cross 8 создан для России.",
      media: { picture: require("@/assets/img/cars/x-cross-8/media/8.webp") },
      gtmReachGoal: "x-cross-8_interior_pin-doors",
    },
    {
      position: {
        x: 4300,
        y: -500,
        z: 0,
      },
      title: 'Мультимедийная система 12,3" с 8-ю динамиками',
      desc: "Легко интегрируется со смартфоном благодаря поддержке систем CarPlay и Android Auto.",
      media: { picture: require("@/assets/img/cars/x-cross-8/media/9.webp") },
      gtmReachGoal: "x-cross-8_interior_pin-doors",
    },
    {
      position: {
        x: 4300,
        y: -1300,
        z: 1700,
      },
      title:
        'Цифровая приборная панель 12,3" с несколькими режимами изображения',
      desc: "Можно всё настроить под свой вкус.",
      media: { picture: require("@/assets/img/cars/x-cross-8/media/10.webp") },
      gtmReachGoal: "x-cross-8_interior_pin-doors",
    },
    {
      position: {
        x: 4300,
        y: -3000,
        z: 700,
      },
      title: "Беспроводное зарядное устройство",
      desc: "В XCITE X-Cross 8 даже для смартфона созданы идеальные условия.",
      media: { picture: require("@/assets/img/cars/x-cross-8/media/11.webp") },
      gtmReachGoal: "x-cross-8_interior_pin-doors",
    },
    {
      position: {
        x: 4300,
        y: -2100,
        z: 2600,
      },
      title: "Мультифункциональное рулевое колесо, отделанное кожей",
      desc: "Чтобы управлять различными системами автомобиля, не отрываясь от вождения.",
      media: { picture: require("@/assets/img/cars/x-cross-8/media/12.webp") },
      gtmReachGoal: "x-cross-8_interior_pin-doors",
    },
    {
      position: {
        x: 4300,
        y: -800,
        z: 750,
      },
      title: "Система кругового обзора 360°",
      desc: "Парковаться и маневрировать в стесненных условиях можно легко и спокойно.",
      media: { picture: require("@/assets/img/cars/x-cross-8/media/13.webp") },
      gtmReachGoal: "x-cross-8_interior_pin-doors",
    },
    {
      position: {
        x: 3300,
        y: -3400,
        z: -500,
      },
      title: "Подключаемый полный привод",
      desc: "Улучшает управляемость и помогает увереннее чувствовать себя на грунтовых дорогах.",
      media: { picture: require("@/assets/img/cars/x-cross-8/media/14.webp") },
      gtmReachGoal: "x-cross-8_interior_pin-doors",
    },
    {
      position: {
        x: 4300,
        y: 1600,
        z: -700,
      },
      title: "Датчики дождя и света",
      desc: "Самостоятельно регулируют режим работы фар и стеклоочистителей.",
      media: { picture: require("@/assets/img/cars/x-cross-8/media/15.webp") },
      gtmReachGoal: "x-cross-8_interior_pin-doors",
    },
    {
      position: {
        x: 4300,
        y: -1900,
        z: 3200,
      },
      title: "Круиз-контроль",
      desc: "Позволяет оставаться свежим и отдохнувшим даже после долгой поездки по трассе.",
      media: { picture: require("@/assets/img/cars/x-cross-8/media/16.webp") },
      gtmReachGoal: "x-cross-8_interior_pin-doors",
    },
    {
      position: {
        x: 4300,
        y: -2700,
        z: -500,
      },
      title: "Двухзонный климат-контроль",
      desc: "Создает комфортные условия в зависимости от предпочтений водителя и пассажиров.",
      media: { picture: require("@/assets/img/cars/x-cross-8/media/17.webp") },
      gtmReachGoal: "x-cross-8_interior_pin-doors",
    },
    {
      position: {
        x: -4300,
        y: -1000,
        z: 300,
      },
      title: "Третий ряд сидений",
      desc: "Путешествуйте большой семьей!",
      media: { picture: require("@/assets/img/cars/x-cross-8/media/18.webp") },
      gtmReachGoal: "x-cross-8_interior_pin-doors",
    },
  ],
};

export const GALLERY = {
  folder: "x-cross-8/exterior",
  colorActive: 0,
  colors: [
    {
      title: "Роса (642)",
      hex: "#918D86",
      folder: "silver",
      fileName: {
        light: "XCsilver_day00",
      },
    },
    {
      title: "Иней (292)",
      hex: "#D9DCDC",
      folder: "white",
      fileName: {
        light: "XCwhite_day00",
      },
    },
    {
      title: "Магнат (609)",
      hex: "#606060",
      folder: "steel",
      fileName: {
        light: "XCsteel_day00",
      },
    },
    {
      title: "Компас (436)",
      hex: "#35475B",
      folder: "compass",
      fileName: {
        light: "XCcompass_day00",
      },
    },
    {
      title: "Лунное затмение (621)",
      hex: "#111111",
      folder: "black",
      fileName: {
        light: "XCblack_day00",
      },
    },
    // {
    //   title: "Парадиз",
    //   hex: "#004CB4",
    //   folder: "blue",
    //   fileName: {
    //     light: "XCblue_day00",
    //   },
    // },
  ],
  breakpoints: [
    {
      slideIndex: 0,
      items: [
        {
          offset: {
            top: "-8vw",
            left: "-12vw",
          },
          offsetMobile: {
            top: "-7",
            left: "-11",
          },
          title: "Рейлинги позволяют разместить багажный ящик на крыше",
          desc: "Берите в путешествие всё необходимое.",
          media: {
            picture: require("@/assets/img/cars/x-cross-8/media/25.webp"),
          },
          gtmReachGoal: "x-cross-8-exterior-pin-led",
        },
        {
          offset: {
            top: "7vw",
            left: "-8vw",
          },
          offsetMobile: {
            top: "4",
            left: "-8",
          },
          title: "Высокий дорожный просвет (190 мм)",
          desc: "XCITE X-Cross 8 универсален в выборе дорог.",
          media: {
            picture: require("@/assets/img/cars/x-cross-8/media/19.webp"),
          },
          gtmReachGoal: "x-cross-8-exterior-pin-led",
        },
        {
          offset: {
            top: "5vw",
            left: "4vw",
          },
          offsetMobile: {
            top: "3",
            left: "2",
          },
          title:
            "18-дюймовые диски с изящными спицами в двухцветном исполнении",
          desc: "C ними большой автомобиль выглядит легким и стремительным.",
          media: {
            picture: require("@/assets/img/cars/x-cross-8/media/20.webp"),
          },
          gtmReachGoal: "x-cross-8-exterior-pin-led",
        },
        {
          offset: {
            top: "0vw",
            left: "14vw",
          },
          offsetMobile: {
            top: "-1",
            left: "9",
          },
          title: "Светодиодные фары",
          desc: "Яркий во всех смыслах элемент дизайна, отлично сочетается с массивной решеткой радиатора.",
          media: {
            picture: require("@/assets/img/cars/x-cross-8/media/21.webp"),
          },
          gtmReachGoal: "x-cross-8-exterior-pin-led",
        },
        {
          offset: {
            top: "-4vw",
            left: "-6vw",
          },
          offsetMobile: {
            top: "-3",
            left: "-5",
          },
          title:
            "Зеркала заднего вида с электрорегулировкой, автоматическим складыванием и указателями поворота",
          desc: "",
          media: {
            picture: require("@/assets/img/cars/x-cross-8/media/23.webp"),
          },
          gtmReachGoal: "x-cross-8-exterior-pin-led",
        },
        {
          offset: {
            top: "-1vw",
            left: "8vw",
          },
          offsetMobile: {
            top: "-1",
            left: "4",
          },
          title: "Передние динамические указатели поворота",
          desc: "Чем лучше вас видно, тем безопаснее маневры.",
          media: {
            picture: require("@/assets/img/cars/x-cross-8/media/24.webp"),
          },
          gtmReachGoal: "x-cross-8-exterior-pin-led",
        },
        {
          offset: {
            top: "-8vw",
            left: "-5vw",
          },
          offsetMobile: {
            top: "-7",
            left: "-5",
          },
          title: "Панорамная крыша с электроприводом",
          desc: "Наслаждайтесь естественным светом и чувством простора в салоне.",
          media: {
            picture: require("@/assets/img/cars/x-cross-8/media/1.webp"),
          },
          gtmReachGoal: "x-cross-8-exterior-pin-led",
        },
        {
          offset: {
            top: "9vw",
            left: "4vw",
          },
          offsetMobile: {
            top: "6",
            left: "-1",
          },
          title: "XCITE X-Cross 8 поможет при экстренном торможении",
          desc: "12 систем безопасности сделают всё, чтобы защитить пассажиров от неприятностей.",
          media: {
            picture: require("@/assets/img/cars/x-cross-8/media/26.webp"),
          },
          gtmReachGoal: "x-cross-8-exterior-pin-led",
        },
      ],
    },
    {
      slideIndex: 140,
      items: [],
    },
    {
      slideIndex: 180,
      items: [
        {
          offset: {
            top: "-4vw",
            left: "15vw",
          },
          offsetMobile: {
            top: "-4",
            left: "10",
          },
          title: "Светодиодная задняя оптика",
          desc: "И монофонарь на всю ширину кузова.",
          media: {
            picture: require("@/assets/img/cars/x-cross-8/media/22.webp"),
          },
          gtmReachGoal: "x-cross-8-exterior-pin-led",
        },
        {
          offset: {
            top: "-4vw",
            left: "21vw",
          },
          offsetMobile: {
            top: "-4",
            left: "16",
          },
          title: "Багажное отделение с объемом от 193 до 2101 л с ровным полом",
          desc: "Выбирайте нужную конфигурацию",
          media: {
            picture: require("@/assets/img/cars/x-cross-8/media/3.webp"),
          },
          gtmReachGoal: "x-cross-8-exterior-pin-led",
        },
        {
          offset: {
            top: "1vw",
            left: "21vw",
          },
          offsetMobile: {
            top: "0",
            left: "13",
          },
          title: "Электропривод двери багажника с дистанционным управлением",
          desc: "",
          media: {
            picture: require("@/assets/img/cars/x-cross-8/media/4.webp"),
          },
          gtmReachGoal: "x-cross-8-exterior-pin-led",
        },
      ],
    },
  ],
};
